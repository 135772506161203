import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Partner } from '../../clients/server.generated';
import PartnerGrid from '../../components/partner/PartnerGrid';
import { getAllPartners } from '../../data/partners';

function PartnersPage() {
  const [partners, setPartners] = React.useState<Partner[] | undefined>(
    undefined,
  );

  React.useEffect(() => {
    const allPartners = getAllPartners();
    setPartners(allPartners);
  }, []);

  if (partners === undefined) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '100%', textAlign: 'center', color: 'white' }}>
      <PartnerGrid partners={partners} extensive />
    </Box>
  );
}

export default PartnersPage;
