import React from 'react';
import AboutPage from '../../views/public/AboutPage';
import PartnersPage from '../../views/public/PartnersPage';
import SpeakersPage from '../../views/public/SpeakersPage';
import { IAuthContext } from '../../auth/AuthContextProvider';
import ProgramComponent from '../program/ProgramComponent';

export interface MenuItem {
  name: string;
  icon?: React.ReactNode;
  target: string;
  component: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  disabled?: (auth: IAuthContext) => boolean;
}

export const generalPages: MenuItem[] = [
  {
    name: 'Speakers',
    target: '/speakers',
    component: <SpeakersPage />,
  },
  {
    name: 'Partners',
    target: '/partners',
    component: <PartnersPage />,
  },
  {
    name: 'Program',
    target: '/program',
    component: <ProgramComponent />,
  },
  {
    name: 'About',
    target: '/about',
    component: <AboutPage />,
  },
];

export const allPages: MenuItem[] = [...generalPages];
