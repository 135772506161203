import React from 'react';
import { Partner } from '../clients/server.generated';
import ThemeComponent from '../components/frontpage/ThemeComponent';
import DateLocationComponent from '../components/frontpage/DateLocationComponent';
import InfoItem from '../components/layout/InfoItem';
import { shuffleArray } from '../helpers/array';
import ThankyouComponent from '../components/frontpage/ThankyouComponent';
import PartnersComponent from '../components/frontpage/PartnersComponent';
import { getAllPartners } from '../data/partners';

function App() {
  const [partners, setPartners] = React.useState<Partner[] | null>(null);
  const [shuffledPartners, setShuffledPartners] = React.useState<
    Partner[] | null
  >(null);

  React.useEffect(() => {
    const allPartners = getAllPartners();
    setPartners(allPartners);
    setShuffledPartners(shuffleArray(allPartners));
  }, []);

  if (!partners || !shuffledPartners) return null;

  return (
    <>
      <InfoItem title="Thank you!" purple inverse>
        <ThankyouComponent />
      </InfoItem>
      <InfoItem title="Theme">
        <ThemeComponent />
      </InfoItem>
      <InfoItem title="Date" purple inverse>
        <DateLocationComponent />
      </InfoItem>
      <PartnersComponent partners={partners} />
    </>
  );
}

export default App;
