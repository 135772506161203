export function getAllSpeakers() {
  const result: any = [{
    id: 1,
    createdAt: '2023-10-05T09:44:18.519Z',
    updatedAt: '2023-10-05T09:44:57.000Z',
    version: 3,
    name: 'Dr Sam Aaron',
    description: 'Dr Sam Aaron is the creator of Sonic Pi, an internationally renowned live coding performer, public speaker and science communicator.  Sam has a PhD in Computer Science and held a research position at the University of Cambridge Computer Laboratory where he initially developed Sonic Pi. \n\n\nSam has been an active community builder for 20 years starting and running the Newcastle and Amsterdam Ruby user groups, Amsterdam Clojure group and more recently the international Sonic Pi community of educators, programmers and artists. \n\n\nSam regularly engages audiences of all ages and backgrounds with the creativity of code through keynotes, workshops and performances.  He has live coded internationally featuring in the Royal Albert Hall, Berlin Warehouses, Music Festivals, on the BBC and even school assemblies. Sam has received two Google prizes for his Open Source work and The Rolling Stone magazine described his Moogfest performance as “transcending the present”.',
    imageFilename: 'partners/544fd757-d115-4307-bfa0-b20d3ff61b33.jpeg',
    activities: [{
      id: 1,
      createdAt: '2023-10-12T13:26:11.285Z',
      updatedAt: '2023-10-24T09:19:18.000Z',
      version: 3,
      name: 'Code Like a DJ with Sonic Pi',
      location: 'Copraloods',
      programPartId: 1,
      description: 'Sonic Pi is a free code-based music creation and performance tool that targets both education and professional musicians. It uses code to take traditional music notation representing notes and melodies and extend it out to to cover studio production techniques and live improvised performance. \n\nUsing Sonic Pi it is possible for complete beginners from as young as 10 to live code fresh beats, driving bass lines and shimmering synth riffs. All this whilst teaching core computer science concepts such as sequencing, functions, variables, loops, data structures and algorithms.\n\nIn this demo-heavy session we’ll take a technical dive into some of the core technical innovations which enable powerful, live expression of music. You’ll leave not only with an understanding of the internal requirements of a live coding environment but also with the knowledge to start coding your own algoraves.',
      recordingUrl: null,
      programPart: {
        id: 1, createdAt: '2023-10-12T13:24:34.978Z', updatedAt: '2023-10-12T13:24:34.978Z', version: 1, name: 'Central closing', beginTime: '2023-11-29T16:15:00.000Z', endTime: '2023-11-29T17:30:45.000Z',
      },
    }],
  }, {
    id: 2,
    createdAt: '2023-10-11T11:38:06.693Z',
    updatedAt: '2023-10-11T11:42:38.000Z',
    version: 2,
    name: 'Mark de Jong',
    description: 'Meet Mark, a starting IT developer who was a student just 2 years ago. During his first project for the Dutch Railways via Info Support, he gained valuable experience in managing large-scale software systems and the importance of observability for such systems. His journey and its many challenges fuels his passion for this topic.',
    imageFilename: 'partners/8fd30a7a-2dc5-4b7e-8485-4b5a16177c09.jpeg',
    activities: [{
      id: 3,
      createdAt: '2023-10-12T13:28:58.398Z',
      updatedAt: '2023-10-31T14:23:58.000Z',
      version: 5,
      name: "Unleashing Observability's Power in SPITS",
      location: 'Loods 8',
      programPartId: 2,
      description: "Explore the future of public transportation during our captivating session, where we introduce the innovative 'SPITS' platform. 'SPITS', Sustainable Public Integrated Transport Solution, represents a transformative public transport platform that redefines the way travelers interact with transportation systems. Experience advanced features such as real-time tracking, predictive analytics, and seamless integration into transportation networks, all aimed at making daily commutes to the campus more sustainable, efficient, and personalized.\n\nIn this session, you will discover how observability is crucial for optimizing the utilization of Mobility as a Service (MAAS) within the innovative 'SPITS' platform. Learn how observability serves as an essential tool in effectively managing the growing demand for transportation services and transforming your student commute. Explore how 'SPITS' harnesses the power of observability to embrace the future of public transportation, resulting in a more connected, efficient, and enjoyable journey to your study destinations!",
      recordingUrl: null,
      programPart: {
        id: 2, createdAt: '2023-10-12T13:26:57.572Z', updatedAt: '2023-10-17T08:19:22.000Z', version: 3, name: 'Third parallel slot', beginTime: '2023-11-29T14:45:00.000Z', endTime: '2023-11-29T15:45:00.000Z',
      },
    }],
  }, {
    id: 3,
    createdAt: '2023-10-11T11:38:30.777Z',
    updatedAt: '2023-10-11T11:44:55.000Z',
    version: 2,
    name: 'Tom van den Berg',
    description: 'Tom is a lead software engineer at the Dutch Railways via Info Support. Here, he and his team work on a mission-critical application that ensures trains run every day in the Netherlands. He enthusiastically shares knowledge about Microsoft Azure and .NET, strongly believing in the significance of enjoying work as a driving force for creativity and team motivation. Tom aims to inspire students and showcase how knowledge sharing and job satisfaction can contribute to a successful career in software engineering',
    imageFilename: 'partners/6bbd4a8d-9a9e-41d0-b72c-91b2a54b72fa.jpeg',
    activities: [{
      id: 3,
      createdAt: '2023-10-12T13:28:58.398Z',
      updatedAt: '2023-10-31T14:23:58.000Z',
      version: 5,
      name: "Unleashing Observability's Power in SPITS",
      location: 'Loods 8',
      programPartId: 2,
      description: "Explore the future of public transportation during our captivating session, where we introduce the innovative 'SPITS' platform. 'SPITS', Sustainable Public Integrated Transport Solution, represents a transformative public transport platform that redefines the way travelers interact with transportation systems. Experience advanced features such as real-time tracking, predictive analytics, and seamless integration into transportation networks, all aimed at making daily commutes to the campus more sustainable, efficient, and personalized.\n\nIn this session, you will discover how observability is crucial for optimizing the utilization of Mobility as a Service (MAAS) within the innovative 'SPITS' platform. Learn how observability serves as an essential tool in effectively managing the growing demand for transportation services and transforming your student commute. Explore how 'SPITS' harnesses the power of observability to embrace the future of public transportation, resulting in a more connected, efficient, and enjoyable journey to your study destinations!",
      recordingUrl: null,
      programPart: {
        id: 2, createdAt: '2023-10-12T13:26:57.572Z', updatedAt: '2023-10-17T08:19:22.000Z', version: 3, name: 'Third parallel slot', beginTime: '2023-11-29T14:45:00.000Z', endTime: '2023-11-29T15:45:00.000Z',
      },
    }],
  }, {
    id: 4,
    createdAt: '2023-10-12T12:59:45.538Z',
    updatedAt: '2023-10-20T07:52:02.000Z',
    version: 3,
    name: 'Luba Elliott',
    description: "Luba Elliott is a curator and researcher specialising in AI art. She works to educate and engage the broader public about the recent developments in AI art through talks and exhibitions at venues across the art, business and technology spectrum including Impakt Festival, The Serpentine Galleries, arebyte, ZKM, The Leverhulme Centre for the Future of Intelligence, CogX, NeurIPS and ICCV. Her projects include the Unit London exhibition 'The Perfect Error' and the ART-AI Festival in Leicester, UK. She founded the NeurIPS Creativity and Design Workshop and curated the online galleries aiartonline.com and computervisionart.com. She is an Honorary Senior Research Fellow at the UCL Centre for Artificial Intelligence. Prior to that, she worked in start-ups, including the art collector database Larry’s List. She obtained her undergraduate degree in Modern Languages at the University of Cambridge.",
    imageFilename: 'partners/02e2b4b3-0ded-4ab4-a372-428ceac01099.png',
    activities: [{
      id: 2,
      createdAt: '2023-10-12T13:28:19.352Z',
      updatedAt: '2023-10-31T14:23:47.000Z',
      version: 5,
      name: 'AI Art: From 2015 till now',
      location: 'Perserij',
      programPartId: 2,
      description: "The popularity of AI art has exploded over the past few years. From its beginnings with DeepDream and style transfer to GANs and DALL·E, AI art has long moved beyond technology circles into the public eye, straddling the worlds of media art, contemporary art and NFTs. The contemporary art world's fascination with the social impact of technologies such as facial recognition, recommendation systems and deep fakes has encouraged artists to explore AI critically as subject matter, while NFTs and text-to-image models have shifted the focus back to aesthetics. This talk will give an overview of how artists and technologists are using and thinking about machine learning, its creative potential and societal impact.",
      recordingUrl: null,
      programPart: {
        id: 2, createdAt: '2023-10-12T13:26:57.572Z', updatedAt: '2023-10-17T08:19:22.000Z', version: 3, name: 'Third parallel slot', beginTime: '2023-11-29T14:45:00.000Z', endTime: '2023-11-29T15:45:00.000Z',
      },
    }],
  }, {
    id: 5,
    createdAt: '2023-10-13T12:24:09.384Z',
    updatedAt: '2023-10-13T12:24:39.000Z',
    version: 2,
    name: 'Rami Ismail',
    description: 'Rami Ismail is a Dutch-Egyptian independent games & tool developer with over 20 titles across PC, console, web, and mobile. The co-founder of the influential now-retired indie games studio Vlambeer, Rami worked on award-winning and genre-defining games such as Ridiculous Fishing and Nuclear Throne. His development of tools like the industry-standard dopresskit.com, his prolific & popular public speaking, and highly-regarded consultancy and insights have helped shape industry opportunities for game communities & independent game developers worldwide. Rami continues to advocate the creation of a fairer and more equal industry for developers anywhere through his work at gamedev.world. His versatility and work resulted in him receiving a wide range of recognition, including the prestigious Game Developers Choice Ambassador Award, the IndieCade Gamechanger Award, and a Forbes 30 Under 30 nomination.',
    imageFilename: 'partners/ff7e05d9-03f0-4121-bad0-e4201b7d170c.png',
    activities: [{
      id: 4,
      createdAt: '2023-10-13T12:25:36.258Z',
      updatedAt: '2023-10-31T14:24:08.000Z',
      version: 5,
      name: 'Games: Software, But Not Quite Software',
      location: 'Copraloods',
      programPartId: 2,
      description: "Games are one of the most exciting places to work, but they're also not quite like other software. Rami Ismail, award-winning games programmer and -designer, walks you through the challenges and opportunities of making games, how they're unique as media and software, and the realities of getting a job in games.",
      recordingUrl: null,
      programPart: {
        id: 2, createdAt: '2023-10-12T13:26:57.572Z', updatedAt: '2023-10-17T08:19:22.000Z', version: 3, name: 'Third parallel slot', beginTime: '2023-11-29T14:45:00.000Z', endTime: '2023-11-29T15:45:00.000Z',
      },
    }],
  }, {
    id: 6,
    createdAt: '2023-10-16T14:36:11.546Z',
    updatedAt: '2023-10-16T14:36:15.000Z',
    version: 2,
    name: 'Lucas Jellema ',
    description: 'Lucas Jellema, CTO at Conclusion and IT Architect for various customers. Loves to explore and apply in unexpected ways - interesting combinations of – new technologies. As well as finding analogies between patterns in the “real” world and in software and IT architecture. Lucas is a connector– between Business and IT but also between juniors and seniors, laymen and experts and between different communities of experts. He wants to make everyone as enthusiastic as he is himself. Lucas likes to write– blog articles, tutorials, two books, open source code – and e enjoys presenting even more. The best way to master a subject is by explaining it to someone else.',
    imageFilename: 'partners/ad515f83-0548-4b47-a8a3-be51a7ae0888.jpeg',
    activities: [{
      id: 5,
      createdAt: '2023-10-16T14:37:42.488Z',
      updatedAt: '2023-10-31T14:24:20.000Z',
      version: 6,
      name: 'Innovolution – the constant in IT is change: how to make the most of it ',
      location: 'Loods 8',
      programPartId: 3,
      description: 'One of the best parts of working in IT is the ability – perhaps even the necessity – to constantly change. Adoption of new concepts and new technologies allow us to constantly innovate. This talk is about facilitating that process of innovation – at organization and team level and at a personal level: how do you participate in this constant change? How do you decide which topics to explore when? How do you turn interesting new options into valuable reality? Computer Vision, Blockchain, Generative AI, European Dataspaces, Zero Trust Security, Streaming Data Analysis, DevOps, and Copilot are some examples of technologies and concepts that emerged in recent years. How did we deal with them? How did we master them, turn them into propositions and applied them in our projects – or decide not to adopt them? Some successful cases are discussed – as well as some examples of failed innovation. Using the Conclusion Emerging Technologies radar, Lucas will explain how innovation in our ecosystem of companies takes place – and which role individual colleagues can play. She who masters a new topic first is the one eyed king in the land of the blind; this allows junior colleagues to quickly play an important part in innovation. At the heart of innovation – on a personal level and on a company level – is the eagerness and ability to learn. How do you keep that continuous learning alive?',
      recordingUrl: null,
      programPart: {
        id: 3, createdAt: '2023-10-16T14:37:08.018Z', updatedAt: '2023-10-17T08:18:52.000Z', version: 2, name: 'Second parallel slot', beginTime: '2023-11-29T13:15:00.000Z', endTime: '2023-11-29T14:15:00.000Z',
      },
    }],
  }, {
    id: 7,
    createdAt: '2023-10-17T11:11:29.468Z',
    updatedAt: '2023-10-17T11:13:24.000Z',
    version: 2,
    name: 'Gina Doekhie',
    description: "Gina is a Digital Detective, Public Speaker and Court Expert. She graduated with a double master’s from the University of Amsterdam. After her bachelor Artificial Intelligence, she specialized in Forensic Intelligence during her master's degree and started a second master: Forensic Science. After working as a digital forensic investigator at Fox-IT for seven years, Gina now works in the Cybercrime Team of the Dutch Police. She is a cybercrime specialist and is actually fighting cybercrime by catching the cybercriminals. She is also a court expert,  where Gina can advise the court of the probative value of digital evidence.\n \nThe mission of Gina is to make the online world a little safer by raising awareness for cybercrime and educating how to protect yourself from digital thieves. She does this by taking the stage as a public speaker, for example as a TedX speaker at the TedXAmsterdamWomen 2019.\n \nIn 2020 Gina was chosen as young talent by the Financieel Dagblad. 2018, 2019, 2020 and 2021 Gina was mentioned in the Opzij Top 100 most influential women in the category Top 10 Tech. The year before she was mentioned in “Inspiring Fifty: The Netherlands 2017”, the most 50 inspiring women in technology.",
    imageFilename: 'partners/48b2ebc5-3e60-471a-88b3-6d1542a76eab.jpeg',
    activities: [{
      id: 6,
      createdAt: '2023-10-17T11:14:17.107Z',
      updatedAt: '2023-10-25T12:48:07.000Z',
      version: 6,
      name: 'The hunt for cybercriminals, how to catch them?!',
      location: 'Perserij',
      programPartId: 3,
      description: 'A phishing panel developer, responsible for victims who had all their savings stolen, was caught by the Cybercrimeteam of the Dutch Police. Gina and her team hunted for several months for this cybercriminal and eventually caught him. In this talk, she will tell and show details of the hunt, what they find during the search of the house and how he got convicted. She will show what type of cybercriminals there are and how they operate with tools like the Darkweb and Telegram. It’s a dark world that Gina will shine her light on in this talk.',
      recordingUrl: null,
      programPart: {
        id: 3, createdAt: '2023-10-16T14:37:08.018Z', updatedAt: '2023-10-17T08:18:52.000Z', version: 2, name: 'Second parallel slot', beginTime: '2023-11-29T13:15:00.000Z', endTime: '2023-11-29T14:15:00.000Z',
      },
    }],
  }, {
    id: 8,
    createdAt: '2023-10-18T13:04:57.885Z',
    updatedAt: '2023-11-21T20:19:42.000Z',
    version: 4,
    name: 'Saskia Freeke',
    description: 'Saskia Freeke is an artist, creative coder and educator. She brings code into play to create generative artworks on a daily basis since January 2015. Doing so she fuses her graphic design and interaction design background into her artistic practice. Specifically, her works and her practice stand out through their distinctive, playful characteristics.',
    imageFilename: 'partners/97c797fa-bc7e-43d2-bded-e4f4b7cad679.jpeg',
    activities: [{
      id: 7,
      createdAt: '2023-10-18T13:06:41.241Z',
      updatedAt: '2023-11-21T20:18:50.000Z',
      version: 7,
      name: 'Playing with code',
      location: 'Copraloods',
      programPartId: 3,
      description: 'In this talk, Saskia Freeke delves into the captivating realm of daily generative art creation through code. Focused on visual patterns and animations, she explores the intriguing concept of employing rules as a playground for artistic expression. Saskia shares insights into the tools essential for crafting these dynamic visual experiences and the iterative process that fuels continuous creativity.',
      recordingUrl: null,
      programPart: {
        id: 3, createdAt: '2023-10-16T14:37:08.018Z', updatedAt: '2023-10-17T08:18:52.000Z', version: 2, name: 'Second parallel slot', beginTime: '2023-11-29T13:15:00.000Z', endTime: '2023-11-29T14:15:00.000Z',
      },
    }],
  }, {
    id: 9,
    createdAt: '2023-10-20T11:31:04.501Z',
    updatedAt: '2023-10-20T11:31:56.000Z',
    version: 2,
    name: 'Azamat Omuraliev',
    description: 'Azamat Omuraliev is a Senior Data Scientist at ING. Cracking the problem of personalization since joining ING in 2020! Decided to stay on this topic because it’s a challenge that requires getting many things right: constructing the right kind of machine learning model, staying in touch with customers and handling millions of interactions daily. Thanks to that, still learning something new on the job every single day.  \nOriginally from Kyrgyzstan, moved to the Netherlands for studies but stayed for friends and for Amsterdam.',
    imageFilename: 'partners/6c9454d8-dbc0-4d59-8211-cc269cd3f7df.png',
    activities: [{
      id: 8,
      createdAt: '2023-10-20T11:32:40.155Z',
      updatedAt: '2023-10-31T14:24:40.000Z',
      version: 7,
      name: 'ING Mobile Banking: Unveiling the Personalized Future',
      location: 'Loods 8',
      programPartId: 4,
      description: 'The times of traditional banking are long gone. Fintechs and challenger banks are disrupting the banking sector and raising the pressure on larger banking institutions. Customers are expecting an easy, smart, and personal banking experience. ING realised early on the potential of the rapid technological movement in the finance sector and shifted its traditional organization to an “agile” model inspired by companies such as Google and Spotify. Azamat and Hanna will talk about the challenges ING’s mobile application is currently facing and how personalization is the key to an enhanced user experience. You will learn about the machine-learning model being used and a new technical concept that is currently being developed to allow an individual banking experience.',
      recordingUrl: null,
      programPart: {
        id: 4, createdAt: '2023-10-20T11:37:07.979Z', updatedAt: '2023-10-20T11:37:07.979Z', version: 1, name: 'First parallel slot', beginTime: '2023-11-29T11:15:34.000Z', endTime: '2023-11-29T12:15:00.000Z',
      },
    }],
  }, {
    id: 10,
    createdAt: '2023-10-20T11:31:16.913Z',
    updatedAt: '2023-10-20T11:31:59.000Z',
    version: 2,
    name: 'Hanna Spanier',
    description: "Hanna is a passionate product owner of a mobile development team with great love and curiosity for technology. For four years at ING, she has strived to create technology that empowers customers and gives them easy control over their finances without fear. She has a mixed background in business, information technology and communications, which allows her to bring cross-functional stakeholders together.\nHer life motivation is 'Never get too comfortable', which prompted her to switch to the tech industry after a successful career in public relations and her love of living abroad in the Netherlands.",
    imageFilename: 'partners/e47760c8-2337-4bea-a8a2-96aff884cf54.jpeg',
    activities: [{
      id: 8,
      createdAt: '2023-10-20T11:32:40.155Z',
      updatedAt: '2023-10-31T14:24:40.000Z',
      version: 7,
      name: 'ING Mobile Banking: Unveiling the Personalized Future',
      location: 'Loods 8',
      programPartId: 4,
      description: 'The times of traditional banking are long gone. Fintechs and challenger banks are disrupting the banking sector and raising the pressure on larger banking institutions. Customers are expecting an easy, smart, and personal banking experience. ING realised early on the potential of the rapid technological movement in the finance sector and shifted its traditional organization to an “agile” model inspired by companies such as Google and Spotify. Azamat and Hanna will talk about the challenges ING’s mobile application is currently facing and how personalization is the key to an enhanced user experience. You will learn about the machine-learning model being used and a new technical concept that is currently being developed to allow an individual banking experience.',
      recordingUrl: null,
      programPart: {
        id: 4, createdAt: '2023-10-20T11:37:07.979Z', updatedAt: '2023-10-20T11:37:07.979Z', version: 1, name: 'First parallel slot', beginTime: '2023-11-29T11:15:34.000Z', endTime: '2023-11-29T12:15:00.000Z',
      },
    }],
  }, {
    id: 11,
    createdAt: '2023-10-20T11:34:31.328Z',
    updatedAt: '2023-10-20T11:34:35.000Z',
    version: 2,
    name: 'Dr. Sezer Karaoglu',
    description: 'Dr. Sezer Karaoglu is Co-Founder and CTO of 3DUniversum, technology spin-off of UvA providing state-of-the art Artificial Intelligence solutions. Sezer is also a part-time postdoctoral researcher at Computer Vision Lab, UvA. His research area is artificial intelligence with the focus on Computer Vision and Synthetic Media (i.e. Generative AI). He has numerous publications in top-tier Computer Vision Journals and Conferences and regularly joins research and industry conferences as an invited speaker. He is actively involved in media. He passionately combines research and practice of computer vision technologies (fairfake.ai, deeptherapy.ai, wescan.io).',
    imageFilename: 'partners/38a5a2f7-2286-49b9-b99d-3d0bbc610cb6.jpeg',
    activities: [{
      id: 9,
      createdAt: '2023-10-20T11:38:06.422Z',
      updatedAt: '2023-10-31T14:24:54.000Z',
      version: 6,
      name: 'Truth in times of Fakeness - Are we ready to accept the full potential of deepfakes aka Generative AI?',
      location: 'Perserij',
      programPartId: 4,
      description: "With the entering of DeepFakes in our lives, the question of what is real and what is fake arises. The lines will be blurred as to what the public can perceive as fake and real, which can incite the propagation of fake news by those with malicious intentions or malign someone's image.\n\n\nIn this talk, Sezer will provide an overview of the rapidly evolving DeepFake technology and their vulnerabilities to spot them. In addition, he will demonstrate how easy it is to use such technology with live demos and show how DeepFake is already changing life paradigms to individuals as well as our whole society.",
      recordingUrl: null,
      programPart: {
        id: 4, createdAt: '2023-10-20T11:37:07.979Z', updatedAt: '2023-10-20T11:37:07.979Z', version: 1, name: 'First parallel slot', beginTime: '2023-11-29T11:15:34.000Z', endTime: '2023-11-29T12:15:00.000Z',
      },
    }],
  }, {
    id: 12,
    createdAt: '2023-10-24T09:10:34.544Z',
    updatedAt: '2023-10-24T09:10:38.000Z',
    version: 2,
    name: 'Yehwan Song',
    description: "Yehwan is a Korean-born web artist and designer. Yehwan's work centers on finding the discomfort hidden in the excessively comfortable web environments in order to address systemic inequality. The work reveals the inconvenience that users who do not belong to the main user group experience and criticizes the overgeneralization of user groups and the oversimplification of web interfaces that pursue excessive comfort and consistency only. Yehwan creates anti-friendly websites and satirizes web conventions by undermining the general understandng of web design and subverting common user-experience behaviors. She pursues diversity above consistency and efficiency.",
    imageFilename: 'partners/0838874b-a5e9-4c13-ad80-484353d84bc4.jpeg',
    activities: [{
      id: 10,
      createdAt: '2023-10-24T09:11:18.744Z',
      updatedAt: '2023-10-31T14:25:03.000Z',
      version: 4,
      name: 'Where are we now?',
      location: 'Copraloods',
      programPartId: 4,
      description: 'Yehwan will talk about her practice of experimenting on the intersection between physical and online space, fostering unexpected interactions with devices and websites, focusing on how current web and user experience practices restrict diversity and deliberately confine individuals within predefined social bubbles and repeated interactions. her practice aims to challenge stereotypes and encourage a process of unlearning, with the ultimate goal of reimagining the current web landscape and sparking conversations about the direction we should strive for going forward.',
      recordingUrl: null,
      programPart: {
        id: 4, createdAt: '2023-10-20T11:37:07.979Z', updatedAt: '2023-10-20T11:37:07.979Z', version: 1, name: 'First parallel slot', beginTime: '2023-11-29T11:15:34.000Z', endTime: '2023-11-29T12:15:00.000Z',
      },
    }],
  }, {
    id: 13,
    createdAt: '2023-10-24T09:14:01.428Z',
    updatedAt: '2023-10-24T09:14:05.000Z',
    version: 2,
    name: 'Eric de Maar',
    description: 'For 15 years, Eric de Maar has been the driving force behind leading technical innovation programs for prominent Dutch organizations. His experience extends from the financial sector with De Nederlandsche Bank and Robeco, the energy sector with Eneco and EDSN to the legal sector with the Raad voor de Rechtspraak.\nFrom this versatility, combined with his experience as an entrepreneur at the Utrecht scale-up Dimaros, Eric de Maar shares his perspective on the parallels between creativity and technological progress - and also the crucial contribution that organizations have in this.\nWhen he is not busy innovating, Eric is becoming an avid table football player - he likes to be challenged by a good opponent to play a game!',
    imageFilename: 'partners/edc7144a-4ddd-49b9-ba05-9a2c0d4a3d25.jpeg',
    activities: [{
      id: 11,
      createdAt: '2023-10-24T09:14:45.558Z',
      updatedAt: '2023-10-24T09:14:45.558Z',
      version: 1,
      name: 'Creativity and Innovation: The Driving Forces of Progress',
      location: 'Copraloods',
      programPartId: 5,
      description: 'Since the dawn of humanity, creativity has guided us through the unknown. It is not strength, but ingenuity that has put us at the top of the food chain. In a rapidly changing world, our creativity brought us groundbreaking innovations: the wheel, the printing press, electricity.\nNow, in an era of emerging artificial intelligence, the question of the importance and essence of our creativity shines even more. Where machines follow, it is still human imagination and creativity that changes the world.\nDuring his presentation, Eric de Maar explores the echoes of contemporary technological milestones in previous inventions and highlights their creative roots - and does not shy away from the social impact they once had. Central to his argument is the role of organizations and the value of teamwork to allow creativity and innovation to flourish.',
      recordingUrl: null,
      programPart: {
        id: 5, createdAt: '2023-10-24T09:13:08.478Z', updatedAt: '2023-10-24T09:13:08.478Z', version: 1, name: 'Central opening', beginTime: '2023-11-29T09:30:00.000Z', endTime: '2023-11-29T10:45:00.000Z',
      },
    }],
  }, {
    id: 14, createdAt: '2023-11-11T13:39:55.875Z', updatedAt: '2023-11-11T13:40:07.000Z', version: 2, name: 'Host: Dr. Sara Lusini', description: 'Dr. Sara Lusini originally comes from Italy but she has been living in the Netherlands for about fifteen years. She obtained a PhD in Theoretical Linguistics from Leiden University in 2013, with a specialization in Syntax. Her dissertation focused on the underlying structure of polar questions in Italian dialects and in the world’s languages. She has been working at the Department of Computer Science at VU Amsterdam for five years, both as an Education Manager and a Teacher. She has a passion for knowledge transfer and coaching students. She teaches several courses in Research Methodology in the Bachelor’s Honours Programme and in the Master Information Sciences. Her interests include both Qualitative and Quantitative research methods, as well as Thesis Design. She has been recently appointed Director of Education for the Department and works closely with the Management Team to ensure the teaching activities run smoothly and receive enough support. In her free time, she enjoys CrossFit classes, dancing, and adventurous travels.', imageFilename: 'partners/0a4c2396-d1e8-45a9-83a7-0a66e1c3709f.jpeg', activities: [],
  }, {
    id: 15, createdAt: '2023-11-22T16:50:37.483Z', updatedAt: '2023-11-22T16:50:48.000Z', version: 3, name: 'Host: Rob de Wit', description: "Rob studied information sciences and business informatics in Utrecht. After graduating in 2019, he worked in various data-related roles for a few different companies. Starting as a data analyst, he soon realized he wanted to learn more about the underlying infrastructure and engineering needed for data science and analytics.\n\nNowadays, he works as a freelance data engineer and occasionally speaks at events in the Python community. If you want to find out about his CreativIT, ask him about generating new Pokémon with stable diffusion. Outside of work, he loves hiking, photography, and climbing.\n\nRob was one of the co-organizers of the 2014 edition of SNiC, and he is excited to experience the conference from a different viewpoint this time. His proudest SNiC memory is from DisruptIT in 2017, where he earned a spot in GEWIS's Supremum (50.1):\n\n\"You can also grab lunch over there.\"\n- Rob: \"Sorry?\"\n\"You can also grab lunch over there.\"\n- Rob: \"Ooh! You won't need to tell me that twice!\"", imageFilename: 'partners/351c1f3a-f228-404d-9189-584a689823c9.jpeg', activities: [],
  }, {
    id: 16, createdAt: '2023-11-22T16:51:59.107Z', updatedAt: '2023-11-22T16:52:03.000Z', version: 2, name: 'Host: Yvonne van der Wal', description: "Yvonne is a data scientist and strategy consultant who is currently working at Amsterdam UMC. Her current focus is on unlocking datasets from various hospital source systems and combining them into insightful dashboards that help make hospitals better.  If unrestrained, she will keep talking for hours about the importance of a clean, solid data architecture and how to bring healthcare related data insights back to the end user. Her contributions lead to innovative and useful solutions in areas where technology was previously seen as a burden rather than an advantage.  Yvonne holds bachelor degrees in social psychology and information sciences, as well as a masters degree in medical informatics. In her downtime, Yvonne can be found hanging out with her cat 'Doutzen Poes' or inline skating in abandoned parking lots.", imageFilename: 'partners/8acbf4c3-c0fd-4f99-b13e-61c509d57e92.jpeg', activities: [],
  }];

  return result;
}
