import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';

function ThankyouComponent() {
  return (
    <>
      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        <Typography variant="h4" sx={{ marginBottom: '1rem' }}>
          Thank you for visiting SNiC 2023: CreativIT!
        </Typography>
      </Container>
      <Container>
        <iframe
          width="560"
          height="315"
          allowFullScreen
          src="https://www.youtube.com/embed/Eh2YZaXXVP4?si=FBrrESlSAO7PFv0P"
          title="SNiC 2023: CreativIT Aftermovie"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </Container>
      <Container sx={{ marginTop: '2rem' }}>
        <Button
          variant="contained"
          color="secondary"
          href="https://drive.google.com/drive/folders/1qeO5u_9uyUWea_8l2RBeF-tZ5euABXbc"
          target="_blank"
        >
          <CameraAlt sx={{ marginRight: '1rem' }} />
          photos
        </Button>
      </Container>
    </>
  );
}

export default ThankyouComponent;
